<template>
  <div class="view pa24">
    <div>
      员工：
      <el-select class="mr20" v-model="userId" clearable>
        <el-option
          v-for="(item, index) in staffs"
          :key="index"
          :label="item.name"
          :value="item.userId"
        >
          {{ item.name }}
        </el-option>
      </el-select>
      来源：
      <el-select class="mr20" v-model="sourcesId" clearable>
        <el-option
          v-for="item in sources"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
          {{ item.name }}
        </el-option>
      </el-select>
      是否授权：
      <el-select class="mr20 mt10" v-model="authsId" clearable>
        <el-option
          v-for="item in auths"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
          {{ item.name }}
        </el-option>
      </el-select>
      浏览时间：
      <el-date-picker
        v-model="pickerValue"
        class="mr10 mt10"
        align="right"
        value-format="yyyy-MM-dd"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      />
    </div>
    <div>
      用户手机：
      <el-input
        v-model="phone"
        class="w220 mt10 mr10"
        placeholder="请输入手机号"
      />
      <el-button type="primary" @click="queryVisitorsData">查询</el-button>
    </div>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column align="center" type="index"> </el-table-column>
        <el-table-column
          prop="nickeName"
          align="center"
          label="微信名称"
          show-overflow-tooltip
        />
        <el-table-column prop="name" align="center" label="业务员" />
        <el-table-column prop="source" align="center" label="来源" />
        <el-table-column prop="phone" align="center" label="手机号">
          <template slot-scope="scope">
            {{ scope.row.phone ? scope.row.phone : '-' }}
          </template>
        </el-table-column>
        <el-table-column prop="visitTime" align="center" label="最近浏览时间">
          <template slot-scope="scope">
            {{ scope.row.visitTime | getDataTimeSec }}
          </template>
        </el-table-column>
        <el-table-column prop="sumNum" align="center" label="总次数" />
        <el-table-column prop="sumTimes" align="center" label="总时长" />
        <el-table-column prop="forwardfNum" align="center" label="转发次数" />
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
import { queryCompanyStaff, queryVisitorsData } from "@/api/articleManagement";
export default {
  name: "visitorData",
  components: {
    commonTable,
  },
  data() {
    return {
      pickerValue: "", //选择时间
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      staffs: [],
      userId: "",
      sources: [
        { id: 0, name: "全部" },
        { id: 3, name: "文章" },
        { id: 2, name: "名片" },
        { id: 1, name: "海报" },
      ],
      sourcesId: "",
      auths: [
        { id: 3, name: "全部" },
        { id: 1, name: "已授权手机" },
        { id: 0, name: "未授权手机" },
      ],
      authsId: "",
      phone: "",
    };
  },
  filters: {
    getDataTimeSec(val) {
      return getDataTimeSec(val);
    },
  },
  created() {
    this.queryCompanyStaff();
    this.queryVisitorsData();
  },
  methods: {
    async queryCompanyStaff() {
      try {
        const result = await queryCompanyStaff({ companyId: 0 });
        this.staffs = result.data;
      } catch (error) {
        console.log(error);
      }
    },
    async queryVisitorsData() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        authPhone: this.authsId,
        userId: this.userId,
        source: this.sourcesId,
        phone: this.phone,
        visitTimeStart: this.pickerValue && `${this.pickerValue[0]} 00:00:00`,
        visitTimeEnd: this.pickerValue && `${this.pickerValue[1]} 23:59:59`,
      };
      try {
        this.loading = true;
        const result = await queryVisitorsData(data);
        this.loading = false;
        const { total, list } = result?.data;
        this.tableData = list;
        this.tableData.forEach(item=>{
          item.salesman = true;
          //1海报 2名片 3 文章
          item.source = item.source == 3 ? "文章" :  item.source == 2 ? "名片" :  item.source == 1 ? "海报":"";
          item.sumTimes = this.formatSeconds(item.sumTimes);
        })
        this.total = total;
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    formatSeconds(value) {
      var theTime = parseInt(value);// 秒
      var theTime1 = 0;// 分
      var theTime2 = 0;// 小时
      if(theTime > 60) {
        theTime1 = parseInt(theTime/60);
        theTime = parseInt(theTime%60);
        if(theTime1 > 60) {
          theTime2 = parseInt(theTime1/60);
          theTime1 = parseInt(theTime1%60);
        }
      }
      var result = ""+parseInt(theTime)+"秒";
      if(theTime1 > 0) {
        result = ""+parseInt(theTime1)+"分"+result;
      }
      if(theTime2 > 0) {
        result = ""+parseInt(theTime2)+"小时"+result;
      }
      return result;
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryVisitorsData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryVisitorsData();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>